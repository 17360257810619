:root {
  --bulma-primary: #008239;
  --bulma-primary-h: 146.31deg;
  --bulma-primary-s: 100%;
  --bulma-primary-l: 25.49%;
  --bulma-link-text: var(--bulma-primary);
}

html, body {
  height: 100%;
}

body {
  display:flex;
  flex-direction:column;
}

header, footer {
  background-color: hsl(0,0%,var(--bulma-scheme-main-bis-l))
}

h2.panel-heading {
  background-color: hsl(0,0%,var(--bulma-scheme-main-bis-l));
  color: hsl(0,0%,var(--bulma-text-strong-l));
}

.navbar {
  --bulma-navbar-burger-color: var(--bulma-primary)
}

a[href$=".pdf"] .level-left {
  padding-left: 20px;
  background: url('../images/icon-pdf.png') 0 center no-repeat;
}

.tag-cloud {
	text-align: center;
	line-height: 1.1;
}

.tag-cloud a {
	vertical-align: middle;
}

.tag {
  background-color: var(--bulma-primary);
  color: white;
  font-weight: bold;
  padding: 0.5em;
  height: 1.5em;
  margin-left: -0.5em;
}

.control input.input:focus {
  box-shadow: 0 0 0 .2rem #00823820;
}